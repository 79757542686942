function slider () {
    let slideIndex = 0, // параметр текущего слайдера
    slides = document.querySelectorAll('.slider-item'),
    prev = document.querySelector('.prev'),
    next = document.querySelector('.next');
    // sliderDots = document.querySelector('.slider-dots'),
    // dots = document.querySelectorAll('.dot');
    
    slides[slideIndex].style.display = 'block';
    showSlide(slideIndex);
    function showSlide(n) {
        if(slideIndex<0){
            slideIndex = slides.length - 1; 
        }else if(slideIndex > slides.length - 1) {
            slideIndex = 0;
        }

        slides.forEach(function(e) {
            e.style.display = 'none';
            let m = slideIndex - 1;
            
            if(m == -1){
                m = slides.length - 1; 
            }else if(m > slides.length - 1) {
                m = 0;
            }else{
                m = slideIndex - 1;
            }
            
            slides[m].style.display = 'block';
            slides[m].className = 'slider-item fadeoff';
            setTimeout(()=> slides[m].style.display = 'none', 1000);
        });
        slides[slideIndex].style.display = 'block';
        slides[slideIndex].className = 'slider-item fadeon';
    }

    function showSlide2(n) {
        if(slideIndex<0){
            slideIndex = slides.length - 1; 
        }else if(slideIndex > slides.length - 1) {
            slideIndex = 0;
        }

        slides.forEach(function(e) {
            e.style.display = 'none';
            let m = slideIndex + 1;
            
            if(m == -1){
                m = slides.length - 1; 
            }else if(m > slides.length - 1) {
                m = 0;
            }else{
                m = slideIndex + 1;
            }
            
            slides[m].style.display = 'block';
            slides[m].className = 'slider-item fadeoff2';
            setTimeout(()=> slides[m].style.display = 'none', 1000);
        });
        slides[slideIndex].style.display = 'block';
        slides[slideIndex].className = 'slider-item fadeon2';
    }


    function nazad(n) {
        showSlide2(slideIndex += n);
    }
    function vpered(n) {
        showSlide(slideIndex += n);
    }

    let timerId = setInterval(() => {
        vpered(1);
    }, 5000);

    prev.addEventListener('click', function() {
        nazad(-1);
        clearInterval(timerId);
    });
    next.addEventListener('click', function() {
        vpered(1);
        clearInterval(timerId);
    });

    // sliderDots.addEventListener('click', function(event) {
    //     for(let i = 0;  i < dots.length + 1; i++) {
    //         if(event.target.classList.contains('dot') && event.target == dots[i-1]) {
    //             currentSlide(i);
    //         }
    //     } 
    // });


}
module.exports = slider;