function popUps() {
    let buttons = document.querySelectorAll('#popUp'),
    popUp = document.querySelector('.pop-up'),
    closePopUp = document.querySelector('.close-popup'),
    advButtons = document.querySelectorAll('#advB'),
    advPopups = document.querySelectorAll('.popAdvantages'),
    mMenu = document.querySelector('.m-menu');

    advButtons.forEach(function(elem, key) {
        elem.addEventListener('click', function(e) {
            e.preventDefault();
            advPopups[key].style.display = 'flex';
            advPopups[key].classList.add('fade');
            document.body.style.overflow = 'hidden';
        });
    });
    advPopups.forEach(function(e){
        e.addEventListener('click', function(e){
            e.preventDefault();
            q = e.target.classList.contains('popAdvantages');
            q2 = e.target.classList.contains('close-popups');
            if(q == true || q2 == true) {
                advPopups.forEach(function(e) {
                    e.style.display = 'none';
                    document.body.style.overflow = '';
                });
            }
        });
    });


    buttons.forEach(function(e) {
        e.addEventListener('click', function(e){
            e.preventDefault();
            popUp.style.display = "block";
            popUp.classList.add('fade');
            mMenu.classList.remove('m-menu__active');
            document.body.style.overflow = 'hidden';
        });
    });
    closePopUp.addEventListener('click', function(e) {
        e.preventDefault();
        popUp.style.display = 'none';
        document.body.style.overflow = '';
    });
    popUp.addEventListener('click', function(e) {
        q = e.target.classList.contains('pop-up');
        if(q == true) {
            popUp.style.display = 'none';
            document.body.style.overflow = '';
        }
    });
}
module.exports = popUps;