function form () {
    let message = {
        loading: 'Загрузка....',
        succes: 'Спасибо! Скоро мы с вами свяжемся!',
        failure: 'Что то пошло не так...'
    };
    //создали сообщения
    let form = document.getElementsByClassName('main-form')[0],
        form2 = document.getElementsByClassName('main-form')[1],//попап формы
        formpopup = document.querySelector('.mobilepopup'),
        mMenuLink = document.querySelector('.m-menu-icon'),
        closeMenu =document.querySelector('.close-menu'),
        mMenu = document.querySelector('.m-menu'),
        statusMsg = document.createElement('div');//создали блок для статус-сообщения
        statusMsg.classList.add('status');//повесили класс на блок статус-сообщения
        

    
    mMenuLink.addEventListener('click', function(event){
        event.preventDefault();
        mMenu.classList.add('m-menu__active');
    });
    closeMenu.addEventListener('click', function(event) {
        event.preventDefault();
        mMenu.classList.remove('m-menu__active');
    });
    
    function sendForm(elem) {// функция отправки формы с аргументом "елем"
        elem.addEventListener("submit", function(e) {//вешаем сабмит на кнопки+функция с аргументом "е"
            e.preventDefault();// аякс
                elem.appendChild(statusMsg);// по клику сабмит создает блок статусМсг 
                let formData = new FormData(elem); // объект запроса

                function postData(data) {//функция отправки

                    return new Promise(function(resolve, reject){//возврат обещания
                        let request = new XMLHttpRequest();// объект запроса

                        request.open('POST', 'smail.php');//опен вид запроса, адрес запроса

                        request.setRequestHeader('Content-type', 'application/json; charset=utf-8');// формат данныъ

                        request.onreadystatechange = function(){//проверка статусов и ответ
                            if(request < 4) {
                                resolve();
                            }else if(request.readyState == 4){
                                if(request.status == 200 && request.status < 300){
                                    resolve();
                                }else{
                                    reject();
                                    console.log(request.status);
                                }
                            }
                        };
                        let obj = {};
                        formData.forEach(function(value, key){
                            obj[key] = value;
                        });
                        let data = JSON.stringify(obj);
                        //превращение данных из объекта в джисон формат
                        request.send(data);//отправка на сервер
                    });

                }//end postdata

                
                //очистка инпутов
                postData(formData).then(()=> statusMsg.innerHTML = message.loading)
                    .then(()=> statusMsg.innerHTML = message.succes)
                    .catch(()=> statusMsg.innerHTML = message.failure);//реджект
        });
    }

    sendForm(form);
    sendForm(form2);
    sendForm(formpopup);



}

module.exports = form;