require('nodelist-foreach-polyfill');
require('formdata-polyfill');

window.addEventListener("DOMContentLoaded", function() {
    'use strict';
    let form = require('./parts/form.js'),
        slider = require('./parts/slider.js'),
        popUps = require('./parts/popUps.js');

    
    form();
    slider();
    popUps();
});
// samat